import {
  isValidAddressLine, isValidEmail, isValid9DigitZip, isValid5DigitZip, isValidName,
  isValidUSOrCanadianPhoneNumber, isValidMexicanPhoneNumber, validateAddress
} from '../../../../utils/validation';
import Constants from '../../../../utils/Constants';
import ToIntIfInt from '../../../../utils/ToIntIfInt';

export const localValidate = async (formState) => {
  let errors = {};

  let addressError = false;

  //Facility Name
  if (formState.facilityName.trim() === '') {
    errors.facilityName = 'Facility Name is required';
  } else if (formState.facilityName.trim().length > 100) {
    errors.facilityName = 'Facility Name cannot exceed 100 characters';
  }
  //Address 1
  if (formState.address1.trim() === '') {
    addressError = true;
    errors.address1 = 'Address Line 1 is required';
  } else if (!isValidAddressLine(formState.address1.trim())) {
    addressError = true;
    errors.address1 = 'Address Line 1 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }
  //Address 2
  if (formState.address2.trim() !== '' && !isValidAddressLine(formState.address2.trim())) {
    addressError = true;
    errors.address2 = 'Address Line 2 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed..';
  }
  //City
  if (formState.city.trim() === '') {
    addressError = true;
    errors.city = 'City is required';
  } else if (!isValidAddressLine(formState.city.trim())) {
    addressError = true;
    errors.city = 'City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  //State
  if (formState.stateCode === Constants.DEFAULT_ID || formState.stateCode.trim() === '') {
    addressError = true;
    errors.stateCode = 'State is required';
  }

  //Zip Code
  if (formState.postalCode.trim() === '' && formState.isInternational === false) {
    addressError = true;
    errors.postalCode = 'Zip Code is required';
  } else if (formState.postalCode.trim() === '' && formState.isInternational === true) {
    addressError = true;
    errors.postalCode = 'Postal Code is required';
  }
  else if (!isValid5DigitZip(formState.postalCode.trim()) && !isValid9DigitZip(formState.postalCode.trim()) && formState.isInternational === false) {
    addressError = true;
    errors.postalCode = 'Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format';
  } else if (formState.postalCode.trim().length > 10 && formState.isInternational === true) {
    addressError = true;
    errors.postalCode = 'Postal Code cannot exceed 10 characters';
  }

  if (addressError === false
    && formState.isInternational === false
    && formState.showedAddressSuggestion === false) {
    formState.validatedAddress = await validateAddress(formState.address1?.trim(), formState.address2?.trim(), formState.city?.trim(), formState.stateCode, formState.postalCode?.trim());
    if (formState.validatedAddress.isValid === true
      && (
        formState.address1?.trim() !== formState.validatedAddress.address1?.trim()
        || formState.address2?.trim() !== formState.validatedAddress.address2?.trim()
        || formState.city?.trim() !== formState.validatedAddress.city?.trim()
        || formState.stateCode !== formState.validatedAddress.stateCode
        || formState.postalCode?.trim() !== formState.validatedAddress.postalCode?.trim()
      )
    ) {
      if (formState.address1?.trim() !== formState.validatedAddress.address1?.trim()) {
        errors.address1 = 'See Address Suggestion';
      }
      if (formState.address2?.trim() !== formState.validatedAddress.address2?.trim()) {
        errors.address2 = 'See Address Suggestion';
      }
      if (formState.city?.trim() !== formState.validatedAddress.city?.trim()) {
        errors.city = 'See Address Suggestion';
      }
      if (formState.stateCode !== formState.validatedAddress.stateCode) {
        errors.stateCode = 'See Address Suggestion';
      }
      if (formState.postalCode?.trim() !== formState.validatedAddress.postalCode?.trim()) {
        errors.postalCode = 'See Address Suggestion';
      }
    }
  }

  //Country
  if (formState.country === Constants.DEFAULT_ID || formState.country.trim() === '') {
    errors.country = 'Country is required';
  } else if (formState.isInternational !== true && formState.country !== 'US') {
    errors.country = 'Country should be United States'
  } else if (formState.isInternational === true && formState.country === 'US') {
    errors.country = "Country cannot be United States when International is set to yes"
  }

  //Contact Name
  if (formState.contactName.trim() !== '') {
    if (!isValidName(formState.contactName.trim())) {
      errors.contactName = 'Facility Contact Name cannot contain numbers, special characters, or exceed 100 characters';
    }
  }

  //Contact Email Address
  if (formState.contactEmailAddress.trim() !== '') {
    if (!isValidEmail(formState.contactEmailAddress.trim())) {
      errors.contactEmailAddress = 'Facility Contact Email Address must be in a valid format';
    } if (formState.contactEmailAddress.trim().length > 512) {
      errors.contactEmailAddress = 'Facility Contact Email cannot exceed 512 characters'
    }
  }

  //Contact Phone Number
  if (formState.contactPhoneNumber.trim() !== '' && !Constants.PHONE_AREA_CODES.find(x => x === formState.contactPhoneNumber.trim())) {
    if (!isValidUSOrCanadianPhoneNumber(formState.contactPhoneNumber) && !isValidMexicanPhoneNumber(formState.contactPhoneNumber)) {
      errors.contactPhoneNumber = 'Please enter a valid US, Canadian, or Mexican phone number in +1/+52 (XXX) XXX-XXXX format'
    }
  }

  //Altitude
  if (formState.altitude === '') {
    errors.altitude = 'Facility Altitude is required';
  } else if (!(typeof formState.altitude == 'number')) {
    const intAlt = ToIntIfInt(formState.altitude.replace(',', ''));
    if (!(typeof intAlt == 'number')) {
      errors.altitude = 'Facility Altitude must be a valid number';
    }
    else if (intAlt > 30000) {
      errors.altitude = 'Facility Altitude cannot exceed 30,000 ft';
    }
  } else if ((typeof formState.altitude == 'number')) {
    if (formState.altitude > 30000) {
      errors.altitude = 'Facility Altitude cannot exceed 30,000 ft';
    }
  }

  //Club Affiliation 
  if (formState.clubAffiliationId < 1) {
    errors.clubAffiliationId = 'Club Affiliation is required';
  }

  if (formState.hostMeet !== true && formState.hostMeet !== false) {
    errors.hostMeet = 'Must select yes or no'
  }

  return errors;
};

const FacilityRepoFacilityValidation = async (formState) => {
  let errors = await localValidate(formState);

  return errors ? errors : {};
};

export default FacilityRepoFacilityValidation;