import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from '../../../../common/components/searches/searchFacility/addFacility/FacilityRepoFacilityValidation';

import NavLinks from '../NavLinks';

import useFacilityData from '../../../../common/state/facility/UseFacilityData';
import useClubAffiliationTypeData from '../../../../common/state/clubAffiliationType/UseClubAffiliationTypeData';
import useCountryData from '../../../../common/state/country/UseCountryData';

import useStatesCombobox from '../../../../common/components/comboboxes/statesCombobox/UseStatesCombobox';
import useCountryCombobox from '../../../../common/components/comboboxes/countryCombobox/UseCountryCombobox';

import Constants from '../../../../common/utils/Constants';
import useForm from '../../../../common/utils/UseForm';

const US_COUNTRY_CODE = 'US';

const INITIAL_VALIDATE_STATE = {
  isValidating: false,
  validatedAddress: null
};

const useFacilityInfo = () => {
  const navigate = useNavigate();
  const { facilityState, putFacility, confirmSave, deleteFacility } = useFacilityData();
  const { clubAffiliationTypeState } = useClubAffiliationTypeData();
  const { getStateNameById } = useStatesCombobox(false, true, false, false);
  const { getCountryNameById } = useCountryCombobox();
  const { countryData } = useCountryData();
  const [state, setState] = useState({ deleteClicked: false, handleCountry: false, initialLoadComplete: false });
  const [modalState, setModalState] = useState(getInitialModalState);
  const [validateState, setValidateState] = useState(INITIAL_VALIDATE_STATE);
  const { formState, errorState, onValueTextPairChanged, updateFormState,
    handleSubmit, resetForm, setFormData
  } = useForm(getFormInitialState(), submitFormCallback, validate);

  const handleSubmitProxy = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setValidateState({
      isValidating: true
    });
    await handleSubmit();
    setValidateState({
      isValidating: false,
      validatedAddress: formState.validatedAddress
    });
    updateFormState('showedAddressSuggestion', true);
  };

  const onDeleteFacility = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      displayPopUp: true,
      facilityName: facilityState.objData.facilityName,
      facilityCity: facilityState.objData.city,
      facilityStateCode: facilityState.objData.stateCode,
      facilityPostalCode: facilityState.objData.postalCode,
      numOfPools: facilityState.objData.facilityPool.length,
      facilityId: facilityState.objData.facilityId
    });
  };

  const onClickDeleteFacility = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (facilityState.isSaving === false) {
      setModalState(getInitialModalState);
      deleteFacility(facilityState.objData.facilityId);
      setState({ ...state, deleteClicked: true });
    }
  };

  const onModalCanceled = () => {
    setModalState(getInitialModalState);
  };

  useEffect(() => {
    if (facilityState.isObjLoaded === true && clubAffiliationTypeState.isOptionsLoaded === true && countryData.length > 1) {
      setValidateState(INITIAL_VALIDATE_STATE);

      setFormData({
        ...formState,
        facilityName: facilityState.objData.facilityName || '',
        address1: facilityState.objData.address1 || '',
        address2: facilityState.objData.address2 || '',
        city: facilityState.objData.city || '',
        stateCode: facilityState.objData.stateCode || '',
        stateName: getStateNameById(facilityState.objData.stateCode) || '',
        country: getCountryNameById(facilityState.objData.country) !== '' ? facilityState.objData.country : facilityState.objData.isInternational === false ? US_COUNTRY_CODE : '',
        countryName: getCountryNameById(facilityState.objData.country) || '',
        postalCode: facilityState.objData.postalCode || '',
        contactName: facilityState.objData.contactName || '',
        contactEmailAddress: facilityState.objData.contactEmailAddress || '',
        contactPhoneNumber: facilityState.objData.contactPhoneNumber || Constants.PHONE_AREA_CODES[0],
        hostMeet: facilityState.objData.hostMeet ?? false,
        clubAffiliationId: facilityState.objData.clubAffiliationId || Constants.DEFAULT_ID,
        clubAffiliationName: facilityState.objData.clubAffiliation?.typeName || '',
        altitude: facilityState.objData.altitude ?? '',
        isInternational: facilityState.objData.isInternational ?? false,
        depthRequirementMet: facilityState.objData.depthRequirementMet ?? false
      });
      setState({ ...state, handleCountry: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityState, clubAffiliationTypeState, countryData]);

  useEffect(() => {
    if (facilityState.isObjLoaded === true && clubAffiliationTypeState.isOptionsLoaded === true && countryData.length > 1 && state.handleCountry === true) {
      setFormData({
        ...formState,
        country: getCountryNameById(facilityState.objData.country) !== '' ? facilityState.objData.country : facilityState.objData.isInternational === false ? US_COUNTRY_CODE : '',
        countryName: getCountryNameById(facilityState.objData.country) || ''
      });
      setState({ ...state, handleCountry: false, initialLoadComplete: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityState, clubAffiliationTypeState, countryData, state.handleCountry]);

  useEffect(() => {
    if (state.initialLoadComplete === true) {
      if (formState.isInternational === false) {
        setFormData({
          ...formState,
          country: US_COUNTRY_CODE,
          countryName: getCountryNameById(US_COUNTRY_CODE)
        }, true);
      }
      else {
        setFormData({
          ...formState,
          country: '',
          countryName: ''
        }, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isInternational]);

  useEffect(() => {
    if (facilityState.isSaved === true && facilityState.isSaving === false
      && state.deleteClicked === false) {
      confirmSave();
    }
    if (facilityState.isSaved === true && facilityState.isSaving === false
      && state.deleteClicked === true) {
      navigate(NavLinks.FACILITY_SEARCH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityState, state]);

  function submitFormCallback() {
    if (facilityState.isSaving === false) {
      putFacility(facilityState.objData.facilityId, createPutFacilityObject());
    }
  };

  function createPutFacilityObject() {
    return {
      ...facilityState.objData,
      facilityName: formState.facilityName.trim(),
      address1: formState.address1.trim(),
      address2: formState.address2.trim(),
      city: formState.city.trim(),
      stateCode: formState.stateCode,
      postalCode: formState.postalCode.trim(),
      contactName: formState.contactName.trim(),
      contactEmailAddress: formState.contactEmailAddress.trim(),
      contactPhoneNumber: formState.contactPhoneNumber.trim() !== '' && !Constants.PHONE_AREA_CODES.find(x => x === formState.contactPhoneNumber.trim()) ? formState.contactPhoneNumber.trim() : '',
      hostMeet: formState.hostMeet,
      clubAffiliationId: formState.clubAffiliationId,
      clubAffiliation: undefined,
      altitude: formState.altitude.toString().replace(',', ''),
      isInternational: formState.isInternational,
      country: formState.country.trim(),
      depthRequirementMet: formState.depthRequirementMet
    };
  };

  return {
    formState,
    errorState,
    facilityState,
    modalState,
    onFormValueChanged: updateFormState,
    resetForm,
    onValueTextPairChanged,
    onDeleteFacility,
    onClickDeleteFacility,
    onModalCanceled,
    handleSubmitProxy,
    setFormData,
    validateState
  };
};

export default useFacilityInfo;

/****************************************************************/
/*  Initial State                                               */
function getFormInitialState() {
  return {
    facilityName: '',
    address1: '',
    address2: '',
    city: '',
    stateCode: '',
    stateName: '',
    country: '',
    countryName: '',
    postalCode: '',
    contactName: '',
    contactEmailAddress: '',
    contactPhoneNumber: '',
    hostMeet: false,
    clubAffiliationId: Constants.DEFAULT_POSITIVE_ID,
    clubAffiliationName: '',
    altitude: '',
    isInternational: false,
    validatedAddress: null,
    showedAddressSuggestion: false
  };
};

function getInitialModalState() {
  return {
    modalTitle: 'Delete this Facility and its Pool(s)?',
    displayPopUp: false,
    facilityName: '',
    facilityCity: '',
    facilityStateCode: '',
    facilityPostalCode: '',
    numOfPools: '',
    facilityId: Constants.DEFAULT_ID
  };
};